import React, { useCallback, useEffect, useRef, useState } from 'react';
import LazyImageLoader from './LazyImageLoader';

import classNames from 'classnames';

import styles from './LazyImage.css';

export enum ImageTypes {
    SQUARE = 'square', // thumb2x
    WIDE = 'wide', // secondary-thumb2x
    PROMO = 'promo', // promo-thumb
}

type ColorBlockProps = {
    color: string;
};

type PictureProps = {
    vertical: boolean;
    alt: string;
    srcSet: string;
    imgSrc: string;
    loader?: boolean;
};

type ProgressiveImageProps = {
    img: string;
    imageType?: ImageTypes;
    alt?: string;
    webp?: string;
    className?: string;
    vertical?: boolean;
    bgColor?: string;
    loader?: boolean;
};

const DEFAULT_BG_COLOR = 'transparent';
const IMAGE_ALT = 'Game Image';
const ROOT_MARGIN_VALUE = '400px';

export const LazyImage = React.memo(
    ({
        bgColor = DEFAULT_BG_COLOR,
        alt = IMAGE_ALT,
        vertical = false,
        imageType,
        img = '',
        webp = '',
        className,
        loader = false,
    }: ProgressiveImageProps) => {
        const [loading, setLoading] = useState(true);
        const containerRef = useRef<HTMLDivElement | null>(null);
        const observerRef = useRef<IntersectionObserver | null>(null);

        const onIntersect = useCallback((entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setLoading(false);
                }
            });
        }, []);

        const initLoad = useCallback(() => {
            observerRef.current = new IntersectionObserver(onIntersect, {
                rootMargin: ROOT_MARGIN_VALUE,
                threshold: 0.1,
            });
            if (containerRef.current) {
                observerRef.current.observe(containerRef.current);
            }
        }, [onIntersect]);

        useEffect(() => {
            initLoad();
            return () => observerRef.current?.disconnect();
        }, [img, initLoad]);

        const pictureClassName = classNames(styles.progressiveContainer, className, {
            [styles.square]: imageType === ImageTypes.SQUARE,
            [styles.wide]: imageType === ImageTypes.WIDE,
            [styles.promo]: imageType === ImageTypes.PROMO,
        });

        return (
            <div className={pictureClassName} data-imagetype={imageType} ref={containerRef}>
                {loading ? (
                    loader && <LazyImageLoader />
                ) : (
                    <Picture vertical={vertical} srcSet={webp} alt={alt} imgSrc={img} loader={loader} />
                )}
            </div>
        );
    }
);
LazyImage.displayName = 'LazyImage';

const ColorBlock = ({ color }: ColorBlockProps) => (
    <div className={classNames(styles.colorBlock)} style={{ backgroundColor: color }} />
);

const Picture = ({ vertical, srcSet, imgSrc, alt, loader }: PictureProps) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const handleImageLoad = useCallback(() => {
        setIsImageLoaded(true);
    }, []);

    return (
        <>
            {!isImageLoaded && loader && (
                <div style={{ borderRadius: '0.625rem' }}>
                    <LazyImageLoader />
                </div>
            )}
            <picture
                className={classNames(styles.picture, {
                    [styles.vertical]: vertical,
                })}
            >
                <source srcSet={srcSet} type="image/webp" />
                <img
                    className={classNames(styles.progressiveImageBase, {
                        [styles.vertical]: Boolean(vertical),
                    })}
                    src={imgSrc}
                    alt={alt}
                    onLoad={handleImageLoad}
                    loading="lazy"
                />
            </picture>
        </>
    );
};
