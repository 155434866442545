import { environment } from '../client/config/environment';
import styles from '../client/templates/Blog/BlogPostModularTemplate/BlogPostModularTemplate.css';

export class StringUtils {
    public static equalIgnoreCase(a: string, b: string): boolean {
        return a?.toLowerCase?.() === b.toLowerCase();
    }

    public static containsIgnoreCase(searchIn: string, searchFor: string): boolean {
        searchIn = searchIn.toLowerCase();
        searchFor = searchFor.toLowerCase();
        return searchIn.indexOf(searchFor) >= 0;
    }

    public static clearPathname(pathname: string): string {
        const str = pathname.includes('?') ? pathname.split('?')[0] : pathname;
        let result = str.endsWith('/') ? str.slice(0, -1) : str;

        result = result.startsWith('/') ? result.slice(1) : result;
        return result;
    }

    // TODO: consider create separate page for 500 status code response
    public static render500Page(title: string, e: Error): string {
        const errInfo = `<p><strong>Message:</strong> ${e.message}</p>
                         <p><strong>Stack:</strong> ${e.stack}</p>`;
        const errorHtml = `<h3>Internal Server Error.</h3>
                           <h4>(${title})</h4>
                           ${environment.ERROR_DETAILS ? errInfo : ``}`;

        return errorHtml;
    }

    public static trimWhiteSpaces(str: string): string {
        let trimmedString = '';

        for (const char of str) {
            if (char !== ' ') {
                trimmedString += char;
            }
        }

        return trimmedString;
    }

    public static handleAnchors (text: string) {
        return text.replace(/\[([^\][]+)]\(([^\s()]+)\)/g, '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>');
    }

    public static handleBold(text: string) {
        return text.replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>');
    }

    public static handleUnderline(text: string) {
        return text.replace(/<u>([^<]+)<\/u>/g, '<span style="text-decoration: underline;">$1</span>');
    }

    public static handleLists(text: string) {
        let lines = text.split('\n');
        let inList = false;
        let listType = null;
        let customCssClass = "";
        const combinedLines = lines.map(line => {
            const currentListType = StringUtils.determineListType(line);

            // ul/ol line
            if (currentListType) {
                let currentListClass = '';

                // add class for <ol> only
                if (currentListType === 'ol') currentListClass = `class="${styles.customOrderedList}"`;

                // list starts
                if (!inList || listType !== currentListType) {
                    inList = true; // switch flag
                    listType = currentListType;
                    customCssClass = currentListClass;
                    // we add an opening tag for the list and li tag
                    return `<${listType} ${customCssClass}>\n<li>${line.replace(/^[-\d.]+\s/, '')}</li>`;
                } else {
                    // a li element
                    return `<li>${line.replace(/^[-\d.]+\s/, '')}</li>`;
                }
            } else {
                // list has just ended we add a closing tag
                if (inList) {
                    inList = false;
                    let content = `</${listType}>\n${line}`;

                    listType = null; // reset flag
                    customCssClass = "";  // reset class
                    return content;
                }

                return line;
            }
        });

        // close tag if needed and return
        return combinedLines.join('\n') + (inList ? `\n</${listType}>` : '');
    }

    private static determineListType(line: string): string | null {
        if (line.startsWith('- ')) return 'ul';
        if (/^\d+\.\s/.test(line)) return 'ol';
        return null;
    }
}
