import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';


import { MenuBody } from './Components/MenuBody';
import { setMobileNavigationUxRedesignedActiveButton } from './MobileNavigationUxRedesign.ducks';
import styles from './MobileNavigationUxRedesigned.css';
import {
  MobileNavButtonProps,
  MobileNavButtonsNames,
  MobileNavButtonsProps
} from './MobileNavigationUxRedesigned.types';
import { devConsole } from '../../../utils/DevConsole';
import { DeviceUtils } from '../../../utils/DeviceUtils';
import { MiscUtils } from '../../../utils/MiscUtils';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { AddGameIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/AddGameIcon';
import { ArkadiumIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/ArkadiumLogo';
import { BlogIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/BlogIcon';
import { CollectionsIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/CollectionsIcon';
import { CommentsIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/CommentsIcon';
import { LBIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/LBIcon';
import { MenuIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/MenuIcon';
import { PlayGameIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/PlayGameIcon';
import { SearchIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/SearchIcon';
import { ShopIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/ShopIcon';
import { SubscriptionTabIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/SubscriptionTabIcon';
import { SupportIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/SupportIcon';
import { NoMobileZoomOn } from '../../atoms/ZoomControls/NoMobileZoomOn';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { PageTypes, ProfileRightSideRoutes } from '../../constants/Pages';
import { GameState } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { HelpFormStatus } from '../../models/HelpFormStatus';
import { SubscriptionSource } from '../../models/Subscription/SubscriptionData';
import { useABTest } from '../../services/ABTests/ABTestReact';
import { Analytics } from '../../services/Analytics/Analytics';
import { GameService } from '../../services/GameService';
import { LocalStorageService } from '../../services/LocalStorage';
import { LocalStorageListenedProps, lsDispatchGamePurchaseRequestFail } from '../../services/LocalStorageListenerLogic';
import { LS_GAME_IN_A_ROW_PROP_NAME, PWAService } from '../../services/PWAService';
import { UrlService } from '../../services/UrlService';
import { AppState } from '../../store';
import { setActiveGameView } from '../../store/ducks/games';
import {
  setHelpTabFormActive,
  setProfilePanelState,
  setSideMenuActivePage,
  setSideMenuOpened
} from '../../store/ducks/layout';
import { activePageSelector } from '../../store/ducks/layoutSelectors';
import { setSubscriptionSource } from '../../store/ducks/subscription/common';
import { LeaderboardNotificationType } from '../Leaderboard/Leaderboard';
import NewMobileBottomNav from '../MobileBottomNav/MobileBottomNav';

export const MobileNavigationUxRedesigned = () => {
  const {
    isSideMenuOpened,
    isHelpFormActive,
    isHomePage,
    isItGamePage,
    isOpenedProfilePanel,
    activeGameNavigationTab,
    gameState,
    gameArkadiumSlug,
    games,
    pages,
    activeButtonName,
    currLang
  } = useSelector((state: AppState) => mapStateToProps(state));
  const dispatch = useDispatch();
  const game = isItGamePage ? GameService.findGameByArkadiumSlug(games, gameArkadiumSlug) : null;
  let isDevelopersPage = false;

  if (window.location.pathname.includes('/developers')) {
    isDevelopersPage = true;
  }

  const isOrientationPortraitByJS = DeviceUtils.isOrientationPortraitByJS();
  const shouldShow = () =>
    !Boolean(
      (DeviceUtils.isOrientationPortraitByJS() &&
        !isSideMenuOpened &&
        !isHelpFormActive &&
        (gameState === GameState.GAME ||
          gameState === GameState.REWARD ||
          gameState === GameState.INTERSTITIAL ||
          gameState === GameState.PREROLL_PLAYING) &&
        activeGameNavigationTab === 'game') ||
      isDevelopersPage
    );
  const [shouldRender, setShouldRender] = useState(shouldShow());

  useEffect(() => {
    setShouldRender(shouldShow());
  }, [isOrientationPortraitByJS, isSideMenuOpened, isHelpFormActive, gameState, activeGameNavigationTab]);

  const [navDisabled, setNavDisabled] = useState(false);

  useEffect(() => {
    setNavDisabled(isItGamePage && [GameState.GAME, GameState.PREROLL_PLAYING].indexOf(gameState) !== -1);
  }, [gameState]);
  const gameAlias = game?.alias || game?.slug;
  const pwaTimeToLpTriggerCheck: boolean =
    shouldShow() &&
    isItGamePage &&
    gameState === GameState.GAME_END &&
    !!gameAlias &&
    !window.matchMedia('(display-mode: standalone)').matches;

  useEffect(() => {
    devConsole(
      'PWA games in row check: ',
      pwaTimeToLpTriggerCheck,
      (window as any)?.localStorage?.getItem?.(LS_GAME_IN_A_ROW_PROP_NAME),
      {
        shouldShow: shouldShow(),
        isItGamePage,
        gameState,
        gameAlias,
        isStandalone: window.matchMedia('(display-mode: standalone)').matches
      }
    );

    if (pwaTimeToLpTriggerCheck) {
      PWAService.PwaGamesInRowLogic(gameAlias);
    }
  }, [gameState]);
  useEffect(() => {
    (window as any)?.document?.documentElement?.classList?.add?.(styles.HtmlNoOverflow);
    return () => (window as any)?.document?.documentElement?.classList?.remove?.(styles.HtmlNoOverflow);
  }, []);
  const [leaveShopIcon, setLeaveShopIcon] = useState(PWAService.pwaOpenMobile());

  useEffect(() => {
    const isAlreadyPwaOpened = PWAService.pwaOpenMobile();
    const isNoPwaOnIos = PWAService.isNoPwaOnIos();
    const doLeaveShopIcon = isAlreadyPwaOpened || isNoPwaOnIos;

    setLeaveShopIcon(doLeaveShopIcon);
  }, []);
  let mobileNavButtonsList: MobileNavButtonsNames[] = isItGamePage
    ? [
      MobileNavButtonsNames.HOME,
      MobileNavButtonsNames.GAME,
      !leaveShopIcon ? MobileNavButtonsNames.ADD_GAME : MobileNavButtonsNames.SHOP,
      MobileNavButtonsNames.LEADERBOARD
    ]
    : [
      MobileNavButtonsNames.HOME,
      MobileNavButtonsNames.SEARCH,
      MobileNavButtonsNames.SHOP,
      MobileNavButtonsNames.SUPPORT
    ];

  mobileNavButtonsList = mobileNavButtonsList.slice(0, 5); // leave only 4 buttons
  mobileNavButtonsList.push(MobileNavButtonsNames.MENU); // add 5-th button - menu
  const mobileNavButtonsProps: MobileNavButtonsProps = mobileNavButtonsPropsData(
    dispatch,
    isItGamePage,
    game,
    isHelpFormActive
  );

  // Handling of active button toggling
  useEffect(() => {
    // turning all off for default view
    batch(() => {
      isOpenedProfilePanel &&
      dispatch(
        setProfilePanelState({
          isOpened: false,
          caption: '',
          target: ProfileRightSideRoutes.NOOP
        })
      );
      // updating side menu state
      activeButtonName &&
      dispatch(
        setSideMenuOpened(
          mobileNavButtonsProps?.[activeButtonName || mobileNavButtonsList[0]]?.usesSideMenu
        )
      );
      // fallback to default view
      !activeButtonName && dispatch(setActiveGameView({ activeTab: 'game' }));
    });

    // running btn onClick logic
    if (activeButtonName === MobileNavButtonsNames.HOME) {
      const homeLinkAffiliate = UrlService.generateHomeLinkForAffiliate(pages);

      isItGamePage && // this line can be removed to change behavior
      !isHomePage &&
      window.location.assign(homeLinkAffiliate);
      return;
    } else {
      mobileNavButtonsProps?.[
        activeButtonName
          ? activeButtonName // if active button name is set
          : isItGamePage // fallback for game page
            ? MobileNavButtonsNames.GAME // it's 'PLAY' button
            : '' // not to auto open home page ('HOME' button)
        ]?.onClick?.(); ///
    }

    if (activeButtonName === MobileNavButtonsNames.ADD_GAME) {
      void Analytics.trackEvent(
        Analytics.general.mobileBookmarkPWAImpression({
          category: PageTypes.Game,
          game,
          platform: PWAService.getDeviceOs()
        }),
        false
      );
    }
  }, [activeButtonName]);
  useEffect(() => {
    // Handling styles for menu opening
    if (activeButtonName === MobileNavButtonsNames.MENU || activeButtonName === MobileNavButtonsNames.ADD_GAME) {
      (window as any)?.document?.body?.classList?.add?.(styles.BodyNoScroll);
    } else {
      (window as any)?.document?.body?.classList?.remove?.(styles.BodyNoScroll);
    }

    return () => (window as any)?.document?.body?.classList?.remove?.(styles.BodyNoScroll);
  }, [activeButtonName]);
  PWAService.pwaInstallSupportFx({ isItGamePage, game, currLang });
  return (
    <>
      <NoMobileZoomOn/>
      <div
        className={classNames(styles.Root, {
          [styles.__MenuOpen]:
          activeButtonName === MobileNavButtonsNames.MENU ||
          activeButtonName === MobileNavButtonsNames.ADD_GAME,
          [styles.__MenuHidden]: navDisabled || !shouldRender
        })}
        style={!shouldRender ? { display: 'none!important' } : {}}
      >
        <div className={classNames(styles.MenuBody)}>
          {activeButtonName === MobileNavButtonsNames.MENU && (
            <MenuBody
              activeButtonName={activeButtonName}
              isItGamePage={isItGamePage}
              isHomePage={isHomePage}
              games={games}
            />
          )}
          {activeButtonName === MobileNavButtonsNames.ADD_GAME && (
            <MenuBody
              activeButtonName={activeButtonName}
              isItGamePage={isItGamePage}
              isHomePage={isHomePage}
              games={games}
              style={{ backgroundColor: '#3d56d7' }}
            />
          )}
        </div>
        <nav className={classNames(styles.Navmenu)}>
          {mobileNavButtonsList.map((buttonName, index) =>
            MobileNavButton({
              ...mobileNavButtonsProps[buttonName],
              name: buttonName,
              indexKey: index,
              activeButtonName: activeButtonName,
              isItGamePage: isItGamePage,
              isHomePage: isHomePage,
              isMenuButton: false
            })
          )}
        </nav>
      </div>
    </>
  );
};

export function MobileNavMenuSplitter(props: { leaderboardNotificationType: LeaderboardNotificationType }) {
  const uxRedesignVariation = 'New'; // useABTest('abtest_MobileNavigation', 'Old');
  const isDevelopment = !MiscUtils.isServer && (window as any).location.port === '4200';
  const checkIsMobile = () => !MiscUtils.isServer && (window as any).innerWidth < 768;
  const checkIsPortrait = () => !MiscUtils.isServer && (window as any).innerWidth < (window as any).innerHeight;
  const checkApplicable = () => checkIsMobile() && checkIsPortrait();
  const [isApplicable, setIsApplicable] = useState(checkApplicable());
  const updateApplicable = () => {
    setIsApplicable(checkApplicable());
  };
  const doUseUxRedesign = isApplicable && (uxRedesignVariation === 'New' || isDevelopment);

  useEffect(() => {
    window.addEventListener('resize', updateApplicable);
    return window.removeEventListener('resize', updateApplicable);
  }, []);
  return doUseUxRedesign ? (
    <MobileNavigationUxRedesigned/>
  ) : (
    <NewMobileBottomNav leaderboardNotificationType={props.leaderboardNotificationType}/>
  );
}

export function MobileNavButton(props: MobileNavButtonProps) {
  const {
    activeButtonName,
    isItGamePage,
    className,
    name,
    iconFuncComp,
    textKey,
    indexKey,
    isMenuButton
  } = props;
  const dispatch = useDispatch();

  return (
    <div
      role="presentation"
      key={indexKey}
      className={classNames(
        styles.Button,
        {
          [styles.__ButtonActive]:
          activeButtonName === name ||
          (!activeButtonName && name === MobileNavButtonsNames.HOME && !isItGamePage) ||
          (!activeButtonName && name === MobileNavButtonsNames.GAME && isItGamePage)
        },
        styles[name],
        className
      )}
      onClick={() => {
        if (isMenuButton) {
          void Analytics.trackEvent(Analytics.mobileNavMenuUxRedesigned.bottomNavMenu(name));
        } else {
          void Analytics.trackEvent(Analytics.mobileNavMenuUxRedesigned.bottomNavSlot(name));
        }

        if (
          // handle game request purchase fail on closing side menu
          isItGamePage &&
          name === MobileNavButtonsNames.GAME &&
          activeButtonName === MobileNavButtonsNames.SHOP
        ) {
          lsDispatchGamePurchaseRequestFail();
          LocalStorageService.removeStorageListening(LocalStorageListenedProps.GAME_PURCHASE_REQUEST);
        }

        // set active button name
        dispatch(setMobileNavigationUxRedesignedActiveButton(name || null));
      }}
    >
      {iconFuncComp()}
      <I18nText keyName={textKey} as="p"/>
    </div>
  );
}

export function mobileNavButtonsPropsData(
  dispatch: Dispatch,
  isItGamePage: boolean,
  game?: IGame,
  isHelpFormActive?: boolean
): MobileNavButtonsProps {
  return {
    [MobileNavButtonsNames.GAME]: {
      iconFuncComp: () => <PlayGameIcon/>,
      textKey: 'PLAY',
      onClick: () => {
        dispatch(setActiveGameView({ activeTab: 'game' }));
        setTimeout(
          () =>
            (window as any)?.document?.body?.scrollIntoView?.({ behavior: 'smooth' }),
          300
        );
      }
    },
    [MobileNavButtonsNames.LEADERBOARD]: {
      iconFuncComp: () => <LBIcon/>,
      textKey: 'LEADERBOARD',
      onClick: () => {
        dispatch(
          setActiveGameView({
            activeTab: 'leaderboard'
          })
        );
      }
    },
    [MobileNavButtonsNames.COMMENTS]: {
      iconFuncComp: () => <CommentsIcon/>,
      textKey: 'COMMENTS',
      onClick: () => {
        dispatch(setActiveGameView({ activeTab: 'game' }));
        setTimeout(() => {
          const commentsButton = (window as any)?.document?.querySelector('button#comments-tab');
          const commentsSection = (window as any)?.document?.querySelector(
            `[class*="PageDescription-descriptionText"]`
          );

          if (commentsButton && commentsSection) {
            commentsButton.click();
            commentsSection.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      }
    },
    [MobileNavButtonsNames.SHOP]: {
      iconFuncComp: () => <ShopIcon/>,
      textKey: 'MOBILE_NAV_SHOP',
      usesSideMenu: true,
      onClick: () => {
        dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SHOP_TAB));
      }
    },
    [MobileNavButtonsNames.COLLECTIONS]: {
      iconFuncComp: () => <CollectionsIcon/>,
      textKey: 'COLLECTIONS',
      onClick: () => {
        dispatch(
          setActiveGameView({
            activeTab: 'collections'
          })
        );

        if (game) {
          void Analytics.trackEvent(Analytics.games.gameNavigationCollectionButton(game));
        }
      }
    },
    [MobileNavButtonsNames.HOME]: {
      iconFuncComp: () => <ArkadiumIcon/>,
      textKey: 'MOBILE_NAV_HOME',
      onClick: () => {
      }
    },
    [MobileNavButtonsNames.SEARCH]: {
      iconFuncComp: () => <SearchIcon/>,
      textKey: 'MOBILE_NAV_SEARCH',
      usesSideMenu: true,
      onClick: () => {
        dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SEARCH_TAB));
      }
    },
    [MobileNavButtonsNames.SUPPORT]: {
      iconFuncComp: () => <SupportIcon/>,
      textKey: 'MOBILE_NAV_SUPPORT',
      usesSideMenu: true,
      onClick: () => {
        batch(() => {
          isHelpFormActive && dispatch(setHelpTabFormActive(new HelpFormStatus(false)));
          dispatch(setSideMenuActivePage(HeaderSideMenuTabs.HELP_TAB));
        });
      }
    },
    [MobileNavButtonsNames.BLOG]: {
      iconFuncComp: () => <BlogIcon/>,
      textKey: 'MOBILE_NAV_BLOG',
      onClick: () => {
        window.location.href = '/blog/';
      }
    },
    [MobileNavButtonsNames.ADVANTAGE]: {
      iconFuncComp: () => <SubscriptionTabIcon/>,
      textKey: 'SUBSCRIPTION_TAB',
      usesSideMenu: true,
      onClick: () => {
        batch(() => {
          dispatch(setSubscriptionSource(SubscriptionSource.MOBILE_MENU));
          dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SUBSCRIPTION_TAB));
        });
      }
    },
    [MobileNavButtonsNames.MENU]: {
      iconFuncComp: () => <MenuIcon/>,
      textKey: 'MENU',
      onClick: () => {
      }
    },
    [MobileNavButtonsNames.ADD_GAME]: {
      usesSideMenu: false,
      iconFuncComp: () => <AddGameIcon/>,
      textKey: 'ADD_GAME',
      onClick: () => {
        ///
        if (PWAService.pwaInstallOpportunity()) {
          // ToDo: can use this for chrome later, now it is ignored for consistency of all browsers behavior
          // PWAService.pwaInstallBtnAction({ dispatch });
        }

        isItGamePage &&
        Analytics.trackEvent(
          Analytics.general.mobileBookmarkPWAClick({
            category: PageTypes.Game,
            game,
            platform: PWAService.getDeviceOs()
          })
        );
      }
    }
  };
}

function mapStateToProps(state: AppState) {
  return {
    isSideMenuOpened: state.sideMenu.isOpened,
    isHelpFormActive: state.sideMenu.helpFormStatus.active,
    activePage: activePageSelector(state),
    user: state.user,
    isHomePage: state.pageType === PageTypes.Home,
    isItGamePage: state.pageType === PageTypes.Game,
    isItProfilePage: state.pageType === PageTypes.Profile,
    isOpenedProfilePanel: state.profilePanel.isOpened,
    activeGameNavigationTab: state.gamePageViewState.activeTab,
    gameState: state.gameState,
    gameArkadiumSlug: state.gameArkadiumSlug,
    games: GameService.gameModelToGame(state.games),
    pages: state.pages,
    activeUserSubscriptions: state.activeUserSubscriptions,
    activeButtonName: state.mobileNavigationUxRedesignedActiveButton,
    currLang: state.currentLang
  };
}
